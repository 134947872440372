import * as React from "react";
import type { SVGProps } from "react";

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.90156 8.32812C5.91326 8.34307 5.92819 8.35515 5.94525 8.36346C5.96231 8.37177 5.98103 8.37609 6 8.37609C6.01897 8.37609 6.03769 8.37177 6.05475 8.36346C6.0718 8.35515 6.08674 8.34307 6.09844 8.32812L7.84844 6.11406C7.9125 6.03281 7.85469 5.9125 7.75 5.9125H6.59219V0.625C6.59219 0.55625 6.53594 0.5 6.46719 0.5H5.52969C5.46094 0.5 5.40469 0.55625 5.40469 0.625V5.91094H4.25C4.14531 5.91094 4.0875 6.03125 4.15156 6.1125L5.90156 8.32812ZM11.7188 7.78125H10.7812C10.7125 7.78125 10.6562 7.8375 10.6562 7.90625V10.3125H1.34375V7.90625C1.34375 7.8375 1.2875 7.78125 1.21875 7.78125H0.28125C0.2125 7.78125 0.15625 7.8375 0.15625 7.90625V11C0.15625 11.2766 0.379687 11.5 0.65625 11.5H11.3438C11.6203 11.5 11.8438 11.2766 11.8438 11V7.90625C11.8438 7.8375 11.7875 7.78125 11.7188 7.78125Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadIcon;
