"use client";
import type { ReactNode } from "react";
import React, { useState } from "react";
import { createContext } from "@/helpers/createContext";

interface Language {
  code: string;
  label: string;
}

interface LanguageContextProps {
  language: string;
  availableLanguages: Language[];
  switchLanguage: (language: string) => void;
}

const [Provider, useLanguageContext] = createContext<LanguageContextProps>();
export { useLanguageContext };

// Define available languages with labels
const availableLanguages = [
  { code: "en", label: "English" },
  { code: "vi", label: "Viet Nam" },
  { code: "zh-cn", label: "Chinese" },
  { code: "cs", label: "Czech" },
  { code: "de", label: "Germany" },
  { code: "es", label: "Spanish" },
  { code: "fr", label: "French" },
  { code: "hr", label: "Croatian" },
  { code: "id", label: "Indonesian" },
  { code: "ja", label: "Japanese" },
  { code: "ko", label: "Korean" },
  { code: "nl", label: "Dutch" },
  { code: "ru", label: "Russian" },
  { code: "ta", label: "Tamil" },
];

export const LanguageProvider: React.FC<{ children: ReactNode; locale: string }> = ({ children, locale }) => {
  const [language, setLanguage] = useState<string>(locale ?? "en");

  const switchLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };
  return <Provider value={{ language, availableLanguages, switchLanguage }}>{children}</Provider>;
};
