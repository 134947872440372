"use client";
import React, { memo, useMemo } from "react";
import { Typography } from "antd/lib";
import type { BaseType } from "antd/es/typography/Base";
import { formatPercent } from "@/helpers";
import classNames from "classnames";

interface PriceChangeProps {
  price: number;
  className?: string;
  hasBgColor?: boolean;
}

const PriceChange: React.FC<PriceChangeProps> = memo(({ price, className, hasBgColor = false }) => {
  const { displayValue, textType } = useMemo(() => {
    let textType: BaseType | undefined = undefined;
    let prefix = "";

    if (price > 0) {
      textType = "success";
      prefix = "+";
    } else if (price < 0) {
      textType = "danger";
    }

    const displayValue = `${prefix}${formatPercent(price)}%`;

    return { displayValue, textType };
  }, [price]);

  return (
    <Typography.Text
      strong
      type={textType}
      className={classNames("min-w-[60px]", className, {
        "bg-[#3de2a033]": hasBgColor && price > 0,
        "bg-[#f02a4533]": hasBgColor && price < 0,
      })}
    >
      {displayValue}
    </Typography.Text>
  );
});
PriceChange.displayName = "PriceChange";
export default PriceChange;
