"use client";

import { signatureKey } from "@/constant/types";
import { addressShorter, isExpired } from "@/helpers";
import { decode, encode } from "base-64";
import { jwtDecode } from "jwt-decode";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useAxiosQuery from "@/hooks/useMyQuery";
import { LOGIN_URL } from "@/constant/apiPath";
import ReactGA from "react-ga";

export type AuthState = "notLogin" | "loading" | "signingIn" | "error" | "logged" | "noRef";

interface PersonalIF {
  first_name?: string;
  last_name?: string;
  phone?: string;
}

interface ProfileIF {
  avatar?: string;
  display_name?: string;
  bio?: string;
}

interface InventorySummaryIF {
  usd: number;
  nft: number;
}

interface AuthContext {
  logout: () => void;
  loginHomePage: () => void;
  redirect: string | undefined;
  setRedirect: Dispatch<SetStateAction<string>>;
  user: UserInfo | undefined | null;
  username: string;
  authState: AuthState;
  authToken: TokenBlob | undefined | null;
  setToken: Dispatch<SetStateAction<TokenBlob | null | undefined>>;
  mutateUser: () => any;
  inventoryData?: { data: InventorySummaryIF };
  handleLaunchAppWithToken: (url?: string, openNewTab?: boolean) => void;
}

export interface TokenBlob {
  walletAddress?: string;
  exp?: number;
  signature?: string;
  isNoRef?: boolean;
  accessToken?: string;
}

export enum UserPlatformEnum {
  Email = "email",
  Twitter = "twitter",
  Telegram = "telegram",
  Facebook = "facebook",
  Discord = "discord",
  Web = "web",
  Medium = "medium",
}

export interface UserPlatformIF {
  platform: UserPlatformEnum;
  display: string;
  data: string;
  is_visible?: boolean;
}

export interface UserInfo {
  id: string;
  username: string;
  email: string;
  enable: boolean;
  nonce?: string;
  role: "user" | "admin";
  status: string;
  referral_code?: string;
  updated_at: string;
  created_at: string;
  wallets: string[];
  user_platform?: UserPlatformIF[];
  personal_information?: PersonalIF;
  profile_information?: ProfileIF;
  recovery_email?: string;
  is_email_confirmed?: boolean;
  toggle_summary?: boolean;
  is_feature_email?: boolean;
  verify_date?: string;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const getStoredSignature = (): TokenBlob | undefined => {
  try {
    const data = JSON.parse(window.localStorage.getItem(signatureKey) || "{}");
    if (isExpired(data.exp || 0)) {
      return undefined;
    }
    return data;
  } catch {
    return undefined;
  }
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const [token, setToken] = useState<TokenBlob | undefined | null>(null);
  const [redirect, setRedirect] = useState<string>("");
  const router = useRouter();
  const searchParams = useSearchParams();

  const { data: userData, refetch: mutateUser } = useAxiosQuery<{ data: UserInfo }>({
    url: `/v1/user/detail`,
    method: "post",
    enabled: !!token,
  });

  const user = useMemo(() => userData?.data, [userData]);

  const authState = useMemo<AuthState>(() => {
    if (token === undefined) {
      // Not get token access yet
      return "notLogin";
    } else if (token === null || (user === undefined && token.accessToken)) {
      // Loading token or loading user info
      return "loading";
    } else if (user === undefined && !token.accessToken) {
      // Under login to account
      return "signingIn";
    } else if (user && token.accessToken) {
      // Login success
      return "logged";
    } else if (user === null && token.accessToken) {
      // Load user info fail or token expired
      return "error";
    } else {
      // Others case, not login
      return "notLogin";
    }
  }, [token, user]);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID || "G-9ZYWCSJ54M");

    // Track page-view on path change
    console.log("pathname", pathname);
    if (pathname) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);

  const logout = useCallback(async () => {
    const redirectPath = window.location.pathname;
    window.localStorage.setItem(signatureKey, "");
    setToken(null);
    window.location.replace(
      `${process.env.NEXT_PUBLIC_LOGIN_URL}?logout=true&from=home&redirect=${encodeURIComponent(redirectPath)}`,
    );
  }, []);

  const handleLaunchAppWithToken = (url?: string, openNewTab = false) => {
    const encodeToken = token ? encode(JSON.stringify(token)) : "";
    if (url === process.env.NEXT_PUBLIC_KLARDA_APP_URL && !token) {
      window.open(url, "_blank");
      return;
    }
    if (url) {
      openNewTab
        ? window.open(`${url}?${token ? `token=${JSON.stringify(encodeToken)}` : ""}`, "_blank")
        : router.replace(`${url}?${token ? `token=${JSON.stringify(encodeToken)}` : ""}`);
    } else {
      router.replace(`${process.env.NEXT_PUBLIC_KLARDA_APP}?token=${JSON.stringify(encodeToken)}`);
    }
  };

  const loginHomePage = useCallback(() => {
    const redirectPath = window.location.pathname;
    router.replace(`${LOGIN_URL}?type=home&redirect=${encodeURIComponent(redirectPath)}`);
  }, [router]);

  useEffect(() => {
    const query = Object.fromEntries(searchParams.entries());
    const isLogout = query.logout === "true";
    const redirectPath = query.redirect ? decodeURIComponent(query.redirect) : window.location.pathname;
    if (query?.token) {
      const decodeToken = decode(JSON.parse(query.token as string) ?? "");
      const result = JSON.parse(decodeToken || "{}");
      const data = jwtDecode(result.accessToken) as any;
      const token = {
        accessToken: result.accessToken,
        exp: data.exp,
        isNoRef: !result.use_ref,
      } as TokenBlob;

      setToken(token);
      window.localStorage.setItem(signatureKey, JSON.stringify(token));
      router.replace(redirectPath);
    } else if (isLogout) {
      window.localStorage.setItem(signatureKey, "");
      setToken(null);
      router.replace(redirectPath || "/");
    } else {
      // Get Token
      const token = getStoredSignature();
      setToken(token);
    }
  }, [logout, router, searchParams]);

  const username = useMemo(
    () =>
      user?.profile_information?.display_name ||
      user?.username ||
      user?.email ||
      addressShorter(user?.wallets && user.wallets[0]),
    [user],
  );
  return (
    <AuthContext.Provider
      value={{
        logout,
        loginHomePage,
        authState,
        user,
        username,
        authToken: token,
        setToken,
        mutateUser,
        redirect,
        setRedirect,
        handleLaunchAppWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
