import * as React from "react";
import type { SVGProps } from "react";

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.96953 8.39766C6.94604 8.37439 6.91431 8.36134 6.88125 8.36134C6.84819 8.36134 6.81646 8.37439 6.79297 8.39766L4.97734 10.2133C4.13672 11.0539 2.71797 11.143 1.78984 10.2133C0.860156 9.28359 0.949219 7.86641 1.78984 7.02578L3.60547 5.21016C3.65391 5.16172 3.65391 5.08203 3.60547 5.03359L2.98359 4.41172C2.9601 4.38846 2.92837 4.37541 2.89531 4.37541C2.86225 4.37541 2.83052 4.38846 2.80703 4.41172L0.991406 6.22734C-0.330469 7.54922 -0.330469 9.68828 0.991406 11.0086C2.31328 12.3289 4.45234 12.3305 5.77266 11.0086L7.58828 9.19297C7.63672 9.14453 7.63672 9.06485 7.58828 9.01641L6.96953 8.39766ZM11.0102 0.991406C9.68828 -0.330469 7.54922 -0.330469 6.22891 0.991406L4.41172 2.80703C4.38846 2.83053 4.37541 2.86225 4.37541 2.89531C4.37541 2.92838 4.38846 2.9601 4.41172 2.98359L5.03203 3.60391C5.08047 3.65234 5.16016 3.65234 5.20859 3.60391L7.02422 1.78828C7.86484 0.947656 9.28359 0.858594 10.2117 1.78828C11.1414 2.71797 11.0523 4.13516 10.2117 4.97578L8.39609 6.79141C8.37283 6.8149 8.35978 6.84663 8.35978 6.87969C8.35978 6.91275 8.37283 6.94448 8.39609 6.96797L9.01797 7.58985C9.06641 7.63828 9.14609 7.63828 9.19453 7.58985L11.0102 5.77422C12.3305 4.45234 12.3305 2.31328 11.0102 0.991406ZM7.53359 3.81797C7.5101 3.79471 7.47837 3.78166 7.44531 3.78166C7.41225 3.78166 7.38052 3.79471 7.35703 3.81797L3.81797 7.35547C3.79471 7.37896 3.78166 7.41069 3.78166 7.44375C3.78166 7.47681 3.79471 7.50854 3.81797 7.53203L4.43672 8.15078C4.48516 8.19922 4.56484 8.19922 4.61328 8.15078L8.15078 4.61328C8.19922 4.56484 8.19922 4.48516 8.15078 4.43672L7.53359 3.81797Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkIcon;
