import type { FC } from "react";

export type DeclareIcon =
  | "twitter"
  | "facebook"
  | "youtube"
  | "telegram"
  | "whitepaper"
  | "global"
  | "web"
  | "github" // Added GitHub
  | "discord"; // Added Discord

export const FaIcon: FC<{
  name: DeclareIcon;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}> = ({ name, className, style, onClick }) => {
  const renderIcon = () => {
    switch (name) {
      case "twitter":
        return (
          <svg
            className={className}
            style={style}
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5849 8.72126L17.7895 1.66406H16.3192L10.9318 7.79173L6.6289 1.66406H1.66602L8.17285 10.9302L1.66602 18.3307H3.13638L8.82561 11.8597L13.3698 18.3307H18.3327L11.5849 8.72126Z"
              fill="currentColor"
            />
          </svg>
        );
      case "github": // GitHub Icon
        return (
          <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.084-.729.084-.729 1.205.085 1.838 1.238 1.838 1.238 1.07 1.835 2.809 1.304 3.495.997.108-.775.419-1.305.762-1.605-2.665-.304-5.466-1.332-5.466-5.93 0-1.31.467-2.381 1.236-3.22-.123-.303-.536-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.005-.404 1.021.005 2.048.138 3.006.404 2.292-1.552 3.299-1.23 3.299-1.23.653 1.653.24 2.874.117 3.176.77.839 1.236 1.91 1.236 3.22 0 4.61-2.803 5.624-5.475 5.921.43.371.824 1.102.824 2.222v3.293c0 .319.219.694.825.576 4.765-1.585 8.2-6.082 8.2-11.384 0-6.627-5.373-12-12-12z" />
          </svg>
        );
      case "discord":
        return (
          <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M20.317 4.369c-1.036-.457-2.14-.794-3.292-.987-.155.258-.337.597-.461.868-1.406-.211-2.785-.211-4.166 0-.125-.271-.309-.61-.461-.868-1.15.193-2.256.53-3.292.987-2.08 3.062-2.632 6.047-2.358 9.011 1.36 1.007 2.65 1.621 3.964 2.014.316-.43.597-.887.846-1.366-.465-.108-.927-.246-1.384-.424.11-.056.222-.112.335-.169 1.301.583 2.683.902 4.058.934 1.375-.032 2.758-.351 4.058-.934.113.057.225.113.335.169-.457.178-.92.316-1.384.424.25.479.531.936.847 1.366 1.314-.393 2.604-1.007 3.964-2.014.386-4.225-.646-7.854-2.358-9.011zM8.594 13.38c-.789 0-1.44-.701-1.44-1.565s.638-1.565 1.44-1.565c.801 0 1.453.701 1.44 1.565-.013.864-.651 1.565-1.44 1.565zm6.812 0c-.789 0-1.44-.701-1.44-1.565s.638-1.565 1.44-1.565c.801 0 1.453.701 1.44 1.565-.013.864-.651 1.565-1.44 1.565z" />
          </svg>
        );
      case "telegram":
        return (
          <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={className}
            style={style}
            onClick={onClick}
            fill="none"
          >
            <path
              fill="currentColor"
              d="M21.603 2.365c-.193-.202-.48-.304-.765-.265l-18.6 2.755c-.571.084-.913.404-.933.94-.02.536.324.827.843.962l4.287 1.245 10.29-5.065c.295-.146.634-.128.82.038.188.166.166.502-.065.78l-7.548 9.038-3.58-1.136a.835.835 0 0 0-.819.201c-.174.168-.224.39-.156.61l1.514 4.838c.192.615.56.801 1.034.518l3.742-2.262 4.936 2.58c.22.115.452.17.675.157.402-.026.72-.317.812-.762l3.352-14.057c.104-.439-.002-.834-.313-1.078z"
            />
          </svg>
        );
      case "whitepaper":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className={className}
            style={style}
            onClick={onClick}
            fill="currentColor"
          >
            <path d="M0 80l0 48c0 17.7 14.3 32 32 32l16 0 48 0 0-80c0-26.5-21.5-48-48-48S0 53.5 0 80zM112 32c10 13.4 16 30 16 48l0 304c0 35.3 28.7 64 64 64s64-28.7 64-64l0-5.3c0-32.4 26.3-58.7 58.7-58.7L480 320l0-192c0-53-43-96-96-96L112 32zM464 480c61.9 0 112-50.1 112-112c0-8.8-7.2-16-16-16l-245.3 0c-14.7 0-26.7 11.9-26.7 26.7l0 5.3c0 53-43 96-96 96l176 0 96 0z" />
          </svg>
        );
      case "global":
      case "web":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={style}
            onClick={onClick}
            fill="currentColor"
          >
            <path d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z" />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={style}
            onClick={onClick}
            fill="currentColor"
          >
            <path d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z" />
          </svg>
        );
    }
  };

  return <>{renderIcon()}</>;
};
