"use client";
import React, { useEffect, useState } from "react";
import { formatThousands } from "@/helpers";
import { usePrice } from "@/context/PriceContext";
import type { TextProps } from "antd/lib/typography/Text";
import Typography from "antd/lib/typography";
import classNames from "classnames";
import { useCurrencyContext } from "@/context/CurrencyContext";

interface TokenPriceProps extends TextProps {
  symbol: string;
  initPrice: number;
  className?: string;
}

const TokenPrice: React.FC<TokenPriceProps> = React.memo(({ symbol, className, initPrice, ...props }) => {
  const { getPrice } = usePrice();
  const { currentCurrency } = useCurrencyContext();
  const [price, setPrice] = useState(getPrice(symbol)?.price || initPrice);
  const [prevPrice, setPrevPrice] = useState<number | null>(null);
  const [flashClass, setFlashClass] = useState<string>("");

  useEffect(() => {
    const newPrice = getPrice(symbol)?.price || initPrice;

    if (prevPrice !== null && newPrice !== price) {
      if (newPrice > price) {
        setFlashClass("flash-green");
      } else if (newPrice < price) {
        setFlashClass("flash-red");
      }

      setPrevPrice(price);
      setPrice(newPrice);

      // Remove the flash class after the animation duration (e.g., 500ms)
      const timeout = setTimeout(() => setFlashClass(""), 500);

      // Clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timeout);
    } else {
      setPrevPrice(price);
      setPrice(newPrice);
    }
  }, [getPrice, initPrice, prevPrice, price, symbol]);

  return (
    <Typography.Text className={classNames("font-semibold", className, flashClass)} {...props}>
      {formatThousands(price, currentCurrency)}
    </Typography.Text>
  );
});

TokenPrice.displayName = "TokenPrice";

export default TokenPrice;
