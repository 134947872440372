"use client";
import React, { createContext, useContext, useMemo } from "react";
import useAxiosQuery from "@/hooks/useMyQuery";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import { useShareModalContext } from "@/context/ShareContext";
import type { TokenData } from "@/models/token";

interface PriceContextType {
  getPrice: (symbol: string) => TokenData | undefined;
  getToken: (symbol: string) => TokenData | undefined;
  isTokenLoading: boolean;
  isPriceLoading: boolean;
  top20Tokens: TokenData[];
  top50Tokens: TokenData[];
  top100Tokens: TokenData[];
  top200Tokens: TokenData[];
  topTrendingTokens: TokenData[];
  topGainers: TokenData[];
  topLosers: TokenData[];
  memeTokens: TokenData[];
  defiTokens: TokenData[];
  nftTokens: TokenData[];
  cryptoExcludingBTC: TokenData[];
  crypto: TokenData[];
  cryptoExcludingTop3: TokenData[];
  cryptoExcludingStableCoins: TokenData[];
  cryptoExcludingTop3AndStableCoins: TokenData[];
  currencyTokens: TokenData[];
  blockchainInfrastructureTokens: TokenData[];
  stableCoins: TokenData[];
  blockchainServiceTokens: TokenData[];
  ceFiTokens: TokenData[];
  gameFiTokens: TokenData[];
  socialTokens: TokenData[];
}
const PriceContext = createContext<PriceContextType | undefined>(undefined);

export const PriceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isOpenShare } = useShareModalContext();
  const { data: priceData, isLoading: isPriceLoading } = useAxiosQuery<{ data: TokenData[] }>(
    {
      url: `/v1/admin/token/public-price`,
      method: "get",
    },
    {
      queryKey: [`priceData`],
      ...(!isOpenShare && { refetchInterval: 5 * 1000 }),
    },
  );

  const { data: tokenData = [], isLoading: isTokenLoading } = useAxiosQuery<TokenData[]>(
    {
      url: `${CLOUDFRONT_URL}/v1/market-movement/token-info/public/market`,
      method: "get",
    },
    {
      queryKey: [`tokenData`],
    },
  );

  const getPrice = useMemo(
    () => (symbol: string) => priceData?.data?.find(item => item.symbol === symbol),
    [priceData],
  );
  const getToken = useMemo(() => (symbol: string) => tokenData?.find(item => item.symbol === symbol), [tokenData]);
  const filterAndSortTokens = (
    filterCondition: (token: TokenData) => boolean,
    sortCondition: (a: TokenData, b: TokenData) => number,
    limit?: number,
  ): TokenData[] => {
    const filtered = tokenData.filter(filterCondition).sort(sortCondition);
    return limit ? filtered.slice(0, limit) : filtered;
  };

  // Filter & Sort conditions
  const allFilter = (token: TokenData) => !token.is_flag;
  const baseFilter = (token: TokenData) => !token.is_flag && !token.stable_coin;
  const volumeAndMarketCapFilter = (token: TokenData) =>
    baseFilter(token) && token.volume >= 100000 && token.market_cap >= 1000000;
  const sortByMarketCapDesc = (a: TokenData, b: TokenData) => b.market_cap - a.market_cap;
  const sortByVolumeDesc = (a: TokenData, b: TokenData) => b.volume - a.volume;
  const sortByChange1DPercentageDesc = (a: TokenData, b: TokenData) =>
    (b.price_change?.change_1D?.percentage || 0) - (a.price_change?.change_1D?.percentage || 0);
  const sortByChange1DPercentageAsc = (a: TokenData, b: TokenData) =>
    (a.price_change?.change_1D?.percentage || 0) - (b.price_change?.change_1D?.percentage || 0);

  // All
  const crypto = filterAndSortTokens(token => allFilter(token), sortByMarketCapDesc);

  // Categories with limits and sorting
  const top20Tokens = filterAndSortTokens(baseFilter, sortByMarketCapDesc, 20);
  const top50Tokens = filterAndSortTokens(baseFilter, sortByMarketCapDesc, 50);
  const top100Tokens = filterAndSortTokens(baseFilter, sortByMarketCapDesc, 100);
  const top200Tokens = filterAndSortTokens(baseFilter, sortByMarketCapDesc, 200);

  const topTrendingTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.market_cap >= 5000000,
    sortByVolumeDesc,
  );

  const topGainers = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && !!token.price_change?.change_1D,
    sortByChange1DPercentageDesc,
  );

  const topLosers = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && !!token.price_change?.change_1D,
    sortByChange1DPercentageAsc,
  );

  const memeTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Meme",
    sortByMarketCapDesc,
  );

  const defiTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "DeFi",
    sortByMarketCapDesc,
  );

  const nftTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "NFT",
    sortByMarketCapDesc,
  );

  const cryptoExcludingBTC = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.symbol !== "BTC",
    sortByMarketCapDesc,
  );

  const cryptoExcludingTop3 = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && !["BTC", "ETH", "BNB"].includes(token.symbol),
    sortByMarketCapDesc,
  );

  const cryptoExcludingStableCoins = filterAndSortTokens(
    token => token.stable_coin && token.volume >= 100000,
    sortByMarketCapDesc,
  );

  const cryptoExcludingTop3AndStableCoins = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && !token.stable_coin && !["BTC", "ETH", "BNB"].includes(token.symbol),
    sortByMarketCapDesc,
  );

  const currencyTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Currency",
    sortByMarketCapDesc,
  );

  const blockchainInfrastructureTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Blockchain Infrastructure",
    sortByMarketCapDesc,
  );

  const stableCoins = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Stablecoin",
    sortByMarketCapDesc,
  );

  const blockchainServiceTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Blockchain Service",
    sortByMarketCapDesc,
  );

  const ceFiTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "CeFi",
    sortByMarketCapDesc,
  );

  const gameFiTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "GameFi",
    sortByMarketCapDesc,
  );

  const socialTokens = filterAndSortTokens(
    token => volumeAndMarketCapFilter(token) && token.category === "Social",
    sortByMarketCapDesc,
  );

  return (
    <PriceContext.Provider
      value={{
        getPrice,
        isPriceLoading: isPriceLoading,
        isTokenLoading: isTokenLoading,
        crypto,
        top20Tokens,
        top50Tokens,
        top100Tokens,
        top200Tokens,
        topTrendingTokens,
        topGainers,
        topLosers,
        memeTokens,
        defiTokens,
        nftTokens,
        cryptoExcludingBTC,
        cryptoExcludingTop3,
        cryptoExcludingStableCoins,
        cryptoExcludingTop3AndStableCoins,
        currencyTokens,
        blockchainInfrastructureTokens,
        stableCoins,
        blockchainServiceTokens,
        ceFiTokens,
        gameFiTokens,
        socialTokens,
        getToken,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = (): PriceContextType => {
  const context = useContext(PriceContext);
  if (!context) {
    throw new Error("usePrice must be used within a PriceProvider");
  }
  return context;
};
