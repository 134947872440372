"use client";
import type { JSXElementConstructor, ReactElement, ReactNode } from "react";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Modal, message } from "antd"; // Added message for notifications
import { createContext } from "@/helpers/createContext";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type { TokenData } from "@/models/token";
import { Button, Typography } from "antd/lib";
import RankLabel from "@/app/components/ProductRank";
import { formatThousands } from "@/helpers";
import PriceChange from "@/app/components/PriceChange";
import { useTimezoneContext } from "@/context/TimezoneContext";
import { useCurrencyContext } from "@/context/CurrencyContext";
import { DateTimeFormat } from "@/constant";
import { TelegramShareButton, TwitterShareButton } from "next-share";
import { FaIcon } from "@/app/components/faIcon";
import { usePathname } from "next/navigation";
import DownloadIcon from "@/svg/DownloadIcon";
import LinkIcon from "@/svg/LinkIcon";
import Image from "next/image";
import { useTranslations } from "next-intl";
import shareLogo from "@/asset/images/share/logo.png";
import shareQR from "@/asset/images/share/qr-share.png";
import Link from "next/link";
import { toPng } from "html-to-image";

interface ShareModalContextType {
  showModal: (props?: ShareProps) => void;
  isOpenShare: boolean;
  ShareModalWrapper: ({
    children,
    footer,
  }: {
    children: ReactElement | JSX.Element | any;
    footer?: ReactNode;
  }) => ReactElement<any, string | JSXElementConstructor<any>> | JSX.Element;
}

export interface ShareProps {
  path?: string;
  shareState?: boolean;
  featureName?: string;
  isShareImg?: boolean;
  contentShare?: string;
  changeUrl?: boolean;
  options?: { type?: string; selectList?: string; duration?: string; updatedTime?: Dayjs; token?: TokenData };
  isCapture?: boolean;
}

const [Provider, useShareModalContext] = createContext<ShareModalContextType>();

export const ShareModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const t = useTranslations();
  const [messageApi, contextHolder] = message.useMessage();
  const contentRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [footerContent, setFooterContent] = useState<ReactNode | null>(null);
  const [propsShare, setPropsShare] = useState<ShareProps | null>(null);
  const { timezone } = useTimezoneContext();
  const { currentCurrency } = useCurrencyContext();
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const pathName = usePathname();

  const showModal = (p?: ShareProps) => {
    setVisible(true);
    if (p) {
      setPropsShare(p);
    }

    if (!p?.featureName) {
      setPropsShare(prev => ({ ...prev, featureName: "Klarda" }));
    }
  };

  const hideModal = () => {
    setVisible(false);
    setContent(null);
    setFooterContent(null);
  };

  const handleCopyLink = async () => {
    const linkToCopy = propsShare?.changeUrl ? propsShare?.path : currentUrl;
    try {
      await navigator.clipboard.writeText(linkToCopy as string);
      messageApi.success("Link copied to clipboard!");
    } catch (err) {
      messageApi.error("Failed to copy link");
    }
  };

  const handleDownloadImage = async () => {
    if (!contentRef.current) return;

    try {
      // Add small delay to ensure all styles are applied
      await new Promise(resolve => setTimeout(resolve, 100));

      const dataUrl = await toPng(contentRef.current, {
        quality: 1.0,
        backgroundColor: "#FFFFFF",
        pixelRatio: 2,
        skipAutoScale: true,
        style: {
          transform: "scale(1)",
        },
        fontEmbedCSS: "", // This will include all used fonts
        filter: node => {
          // Keep all nodes including style elements
          return true;
        },
      });

      const link = document.createElement("a");
      link.download = `klarda-share-${Date.now()}.png`;
      link.href = dataUrl;
      link.click();
      messageApi.success("Image downloaded successfully!");
    } catch (err) {
      console.error("Download error:", err);
      messageApi.error("Failed to download image");
    }
  };

  useEffect(() => {
    if (visible && content) {
      setContent(content);
    }
    if (visible && footerContent) {
      setFooterContent(footerContent);
    }
  }, [visible, content, footerContent]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentUrl(window.location.href);
    }
  }, [pathName]);

  const ShareModalWrapper = ({ children, footer }: { children: ReactElement | JSX.Element; footer?: ReactNode }) => {
    useEffect(() => {
      if (visible) {
        setContent(children);
        footer && setFooterContent(footer);
      }
    }, [children, footer]);

    return children;
  };

  const isShareImg = useMemo(() => propsShare?.options, [propsShare]);

  const shareUrl = useMemo(
    () =>
      propsShare?.contentShare ||
      `Check out ${propsShare?.featureName} by @KlardaOfficial: ${
        propsShare?.changeUrl ? propsShare?.path : currentUrl
      } \nEmpowering your crypto journey! #Klarda`,
    [propsShare, currentUrl],
  );

  return (
    <Provider value={{ showModal, ShareModalWrapper, isOpenShare: visible }}>
      {contextHolder}
      {children}
      <Modal
        open={visible}
        wrapClassName={propsShare?.options ? undefined : "top-[20%]"}
        onCancel={hideModal}
        footer={null}
        width={"100%"}
        destroyOnClose
        style={{ maxWidth: propsShare?.options ? 1080 : 628, minWidth: 350 }}
        styles={{ body: { padding: "0" } }}
        title="Share Content"
      >
        <div className="relative">
          {isShareImg && propsShare?.options && visible && (
            <div className={"bg-[#fff] flex flex-col gap-y-2 py-3 items-center"} ref={contentRef}>
              {propsShare?.options?.token ? (
                <div className="flex gap-2 items-center">
                  <img alt="" src={propsShare?.options?.token.logo_uri || ""} height={24} width={24} />
                  <Typography.Text className="font-semibold text-xl">
                    {`${propsShare?.options?.token?.name} (${propsShare?.options?.token?.symbol})`}
                  </Typography.Text>
                  <RankLabel label={propsShare?.options?.token?.rank_label || ""} />
                  <div className="font-normal text-black">
                    {formatThousands(propsShare?.options?.token?.price, currentCurrency)}
                  </div>
                  <PriceChange price={propsShare.options.token?.price_change_duration} />
                </div>
              ) : (
                <Typography.Text className="text-xl font-semibold">
                  {`${propsShare?.options?.type}: ${propsShare?.options?.selectList} | ${propsShare?.options?.duration}`}
                </Typography.Text>
              )}

              <Typography.Text className="text-sm">
                Update on: {dayjs().tz(timezone.utc).format(DateTimeFormat)}
              </Typography.Text>
              <div className="w-full h-[418px]">{content}</div>
              {footerContent && <div className="w-full">{footerContent}</div>}
            </div>
          )}

          {/* card footer */}
          <div
            className="w-full flex flex-row py-3 bg-[#FAF7FF] rounded mb-6"
            style={{ justifyContent: "space-around" }}
          >
            <Image src={shareLogo.src} width={263} height={56} alt="share-logo" />
            <div className="flex items-center gap-2">
              <p>
                See more at{" "}
                <Link href="https://klarda.com" target="_blank" className="text-primary-blue">
                  https://klarda.com
                </Link>
                .
              </p>

              <Image src={shareQR.src} width={46} height={46} alt="share-qr" />
            </div>
          </div>
          <div className="flex gap-2 w-full items-center justify-end">
            Direct Link:
            <Button className="w-fit cursor-default">
              <span
                className="block truncate max-w-[200px]"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "200px",
                }}
              >
                {propsShare?.changeUrl ? propsShare?.path : currentUrl}
              </span>
            </Button>
            <Button onClick={handleCopyLink} type="default" icon={<LinkIcon />}>
              Copy Link
            </Button>
            <Button onClick={handleDownloadImage} type="default" icon={<DownloadIcon />}>
              Download Image
            </Button>
          </div>
          <div className="w-full flex gap-2 justify-end items-center mt-3">
            <Typography.Text className="text-sm font-normal">Share on social:</Typography.Text>
            <TelegramShareButton url={shareUrl}>
              <Button type="primary" size={"large"} className="flex items-center gap-1">
                <FaIcon name={"telegram"} className="text-white" /> Telegram
              </Button>
            </TelegramShareButton>

            <TwitterShareButton url={shareUrl}>
              <Button className="flex items-center gap-1" type="primary" size={"large"}>
                <FaIcon name="twitter" className="text-red" /> Twitter
              </Button>
            </TwitterShareButton>
          </div>
        </div>
      </Modal>
    </Provider>
  );
};

export { useShareModalContext };
export default ShareModalProvider;
