import { KLARDA_APP_URL } from "@/constant/apiPath";
import { Typography } from "antd/lib";
import Link from "antd/lib/typography/Link";
import { useTranslations } from "next-intl";
import React, { useMemo } from "react";
import ArrowUpRight from "@/svg/ArrowUpRight";
import classNames from "classnames";
import { useAuth } from "@/context/AuthContext";
import { useRouter } from "next/navigation";

interface LinkToKlardaAppProps {
  url?: string;
  isOnlyText?: boolean;
  label?: string;
  className?: string;
}

const LinkToKlardaApp: React.FC<LinkToKlardaAppProps> = ({
  url = KLARDA_APP_URL,
  isOnlyText = false,
  label,
  className,
}) => {
  const t = useTranslations("home");
  const router = useRouter();
  const { handleLaunchAppWithToken } = useAuth();
  const textLabel = useMemo(
    () => label ?? (isOnlyText ? t("Read_more") : t("Klarda_dashboard")),
    [isOnlyText, label, t],
  );

  return isOnlyText ? (
    <Typography.Link
      onClick={e => {
        e.preventDefault();
        router.push(url);
      }}
      href={"/#"}
      className={`text-primary ${className}`}
      style={{ whiteSpace: "nowrap" }}
    >
      {textLabel}
    </Typography.Link>
  ) : (
    <Link
      onClick={e => {
        e.preventDefault();
        handleLaunchAppWithToken(url, true);
      }}
      className={classNames(
        "border border-primary border-solid px-3 py-1 min-w-max rounded-lg flex items-center gap-1 text-primary",
        className,
      )}
      href="#"
      target="_blank"
    >
      {textLabel}
      <ArrowUpRight />
    </Link>
  );
};

export default React.memo(LinkToKlardaApp);
