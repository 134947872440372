import React from "react";

interface ArrowUpRightProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const ArrowUpRight: React.FC<ArrowUpRightProps> = ({ width = 16, height = 16, fill = "#1A56DB", className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83521 3.38889H3.38889C2.62183 3.38889 2 4.01072 2 4.77778V13.1111C2 13.8782 2.62183 14.5 3.38889 14.5H11.7222C12.4893 14.5 13.1111 13.8782 13.1111 13.1111V8.66479C12.5704 8.47369 12.1322 8.06572 11.9009 7.54539L9.80022 9.64606C8.98663 10.4597 7.66753 10.4597 6.85394 9.64606C6.04035 8.83247 6.04035 7.51337 6.85394 6.69978L8.95461 4.59911C8.43429 4.36782 8.02632 3.92958 7.83521 3.38889Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10555 2.69444C9.10555 2.31091 9.41647 2 9.8 2H13.8056C14.1891 2 14.5 2.31091 14.5 2.69444V6.7C14.5 7.08353 14.1891 7.39445 13.8056 7.39445C13.422 7.39445 13.1111 7.08353 13.1111 6.7V4.37098L8.81813 8.66397C8.54693 8.93516 8.10723 8.93516 7.83603 8.66397C7.56484 8.39277 7.56484 7.95307 7.83603 7.68187L12.129 3.38889H9.8C9.41647 3.38889 9.10555 3.07798 9.10555 2.69444Z"
      fill={fill}
    />
  </svg>
);

export default ArrowUpRight;
